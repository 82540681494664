//@flow
import React from "react";
import ReactDOM from "react-dom";

import Navbar from "lib/navbar";
import Signup from "lib/idd-signup";

// import registerServiceWorker from '../registerServiceWorker';
import CookieAccept from "lib/cookie-accept";

const bg = document.getElementById("background");
const sky = document.getElementById("art-sky");

const topnav = document.getElementById("nav-wrapper");
const title = document.getElementById("titlepanel");

let lastScrollY = 0;
let menuPos = 0;
const fixMenuAt = 35;

export const load = () => {
  let ca = document.getElementById("react-cookie-accept");
  if (ca) ReactDOM.render(<CookieAccept />, ca);
  let navbar = document.getElementById("react-navbar");
  if (navbar) ReactDOM.render(<Navbar />, navbar);

  let su = document.getElementById("react-signup");
  if (su)
    ReactDOM.render(
      <Signup
        brandId="1110000000000002"
        timeout={12000}
        firstTitle="Enter your details to get all the Reminisce news & offers first from the Official Reminisce Festival Website"
        secondTitle="Get more from Reminisce - let us know a bit about yourself to get priority access to Tickets and special offers!"
      />,
      su
    );

  // common scrolling animations
  window.addEventListener("scroll", () => {
    window.requestAnimationFrame(() => {
      // background
      // doscroll(bg, 0, -0.04);
      // background2
      doscroll(sky, 0, -0.3);
      // title panel
      // doscroll(title, 15, 0.15);

      //nav
      if (window.scrollY > 0) {
        const diff = (window.scrollY - lastScrollY) * 0.4;
        lastScrollY = window.scrollY;
        menuPos += diff;
        if (menuPos > fixMenuAt) menuPos = fixMenuAt;
        if (menuPos < 0) menuPos = 0;

        if (topnav != null) topnav.style.top = menuPos * -1 + "px";
      }
    });
  });
};

// helper for adding scroll animations
export const doscroll = (el: Object, offset: number, multiplier: number) => {
  const f = window.scrollY * multiplier + offset;
  el.style.top = f + "px";
};
